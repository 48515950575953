import { FormDataType } from '../Contexts/CreateUserContext'
import { isEmpty } from 'lodash'
import {
  phoneNumberValidation,
  validateEmail,
} from '../Views/CreateUser/pageTwo/PageTwo'

const requiredKeysForPage = (page: number, formData: FormDataType): boolean => {
  const lookup = [
    ['birth', 'gender', 'first_name', 'last_name'],
    [
      'address',
      'postal_code',
      'phone_number',
      'email_address',
      phoneNumberValidation(formData.phone_number),
      validateEmail(formData.email_address),
      'terms',
    ],
    ['terms'],
    ['sms_code'],
  ]
  const pagePositive = page > 0 ? page : 0
  return isEmpty(
    lookup[pagePositive].flatMap(key =>
      (typeof key === 'string' ? formData[key as keyof FormDataType] : key)
        ? []
        : [1],
    ),
  )
}

export default requiredKeysForPage
