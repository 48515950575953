import { CardHeader, Grid } from '@mui/material'
import AboutUsCard from '../../Utils/AboutUsCard'
import MainCard from '../MainPage/MainCard'
import TopLogoCard from '../MainPage/TopLogoCard'
import { colors, standardWidthLarger } from '../../themes/generalStyles'
import settings from '../../config'
import { useContext } from 'react'
import Center from '../../Utils/Center'
import screenScaleContext from '../../Contexts/ScreenScaleContext'
import TenStep from '../MainPage/Cards/TenStep'

const AboutUs = () => {
  const { smallScreenWidth } = useContext(screenScaleContext)
  const padding = smallScreenWidth ? 2 : 5
  const listMargin = 30
  return (
    <MainCard
      style={{ boxShadow: colors.shadow }}
      sx={{
        width: standardWidthLarger,
        marginBottom: 10,
      }}
      cardHeader={
        <CardHeader
          title={
            <Grid container>
              <TopLogoCard sx={{ width: '100%' }} content='Om oss' />
            </Grid>
          }
        />
      }>
      <Center>
        <Grid
          container
          rowGap={2}
          sx={{
            maxWidth: 850,
          }}>
          <MainCard
            style={{ boxShadow: colors.shadow }}
            sx={{ padding: padding }}>
            <>
              <div>
                <strong>iSteady</strong> er et norsk online treningstilbud
                tilpasset deg som senior. Vårt mål er bedring/vedlikehold av din
                helse slik at du fungerer best mulig i dagliglivet.
              </div>
              <br />
              <ul>
                <li style={{ marginBottom: listMargin }}>
                  Du kan trene hvor som helst der du har internettilgang og en
                  enhet med kamera og lyd (nettbrett, ipad eller PC).
                </li>
                <li style={{ marginBottom: listMargin }}>
                  Treningstimene man melder seg på skjer i sanntid og ledes av
                  instruktører på faste tidspunkter hver uke.
                </li>
                <li style={{ marginBottom: listMargin }}>
                  Instruktøren viser og forklarer underveis. Deltakerne ser kun
                  instruktøren og seg selv, mens instruktøren ser alle som
                  deltar.
                </li>
                <li style={{ marginBottom: listMargin }}>
                  Vi har også ferdiginspilte treningsvideoer som du kan se når
                  det passer deg.
                </li>
                <li style={{ marginBottom: listMargin }}>
                  Vi tar sikkerheten på alvor. Skulle det oppstå en situasjon
                  under timen, kan instruktøren forsøke å kontakte den det
                  gjelder. Hvis det ikke er mulig å få kontakt, vil instruktøren
                  tilkalle nødetatene for å sikre rask hjelp
                </li>
              </ul>

              <Grid container justifyContent='center'>
                <img
                  alt='about us image'
                  src={`${settings.bucket}/root_images/aboutUs.png`}
                  style={{
                    marginTop: 20,
                    width: '100%',
                    height: 'auto',
                    borderRadius: 10,
                  }}
                />
              </Grid>
            </>
          </MainCard>
          <MainCard
            style={{ boxShadow: colors.shadow }}
            sx={{ padding: padding }}>
            <>
              <div>
                <span style={{ fontSize: 25 }}>
                  <strong>Pådriverne bak iSteady</strong>
                </span>{' '}
                er to treningsglade fysioterapeuter. Sammen med resten av
                isteady- teamet ønsker vi å knagge seniorgarden på teknologien,
                og tilgjengeliggjøre trening og kunnskap.
              </div>
              <Grid container gap={2} marginTop={2}>
                <AboutUsCard
                  name='Benjamin M. Delaveris'
                  image={`${settings.bucket}/root_images/benja.png`}
                  text='Benjamin har et sterkt ønske om å gjøre trening enkelt og tilgjengelig for alle. Han tror på at fysisk aktivitet er en viktig hjørnestein i et sunt og godt liv, og at alle fortjener muligheten til å kunne trene og være i aktivitet.'
                />
                <AboutUsCard
                  name='Christoffer Alfer'
                  image={`${settings.bucket}/root_images/chris.png`}
                  text='Christoffer har en lidenskap for å hjelpe mennesker til å bli mer selvhjulpne og ta kontroll over sitt eget liv. Han håper å inspirere alle til å inkludere regelmessig fysisk aktivitet i sin hverdag.'
                />
              </Grid>
            </>
          </MainCard>
          <TenStep />
          <Grid
            direction='column'
            alignItems='center'
            container
            justifyContent='center'>
            <div
              style={{
                padding: 15,
                textAlign: 'center',
                maxWidth: '500px',
                fontStyle: 'italic',
                backgroundColor: colors.lightGray,
                borderRadius: 35,
              }}>
              «Den beste dagen å begynne å trene på er i dag. I morgen kan bli
              til uker, måneder eller år.» <br />
              <span
                style={{
                  marginTop: 10,
                  fontSize: 15,
                  color: colors.secondGray,
                }}>
                Mark Dilworth
              </span>
            </div>
          </Grid>
        </Grid>
      </Center>
    </MainCard>
  )
}

export default AboutUs
