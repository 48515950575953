import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material'
import { buttonWarningStyle, colors } from '../themes/generalStyles'
import { Dispatch, SetStateAction } from 'react'

const CouponCard = ({
  coupon,
  setCoupon,
  onClick,
  validCoupon,
  discountLoading,
}: {
  coupon: string
  setCoupon: Dispatch<SetStateAction<string>>
  onClick: () => void
  validCoupon: 'idle' | 'success' | 'error'
  discountLoading: boolean
}) => {
  const buttonStyle = {
    ...buttonWarningStyle,
    '&:hover': {
      ...buttonWarningStyle['&:hover'],
      boxShadow: colors.shadow,
      opacity: 0.7,
    },
  }
  return (
    <Grid
      container
      justifyContent='center'
      sx={{ width: '100%', fontSize: 23 }}>
      <Box
        sx={{
          boxShadow: colors.shadow,
          backgroundColor: validCoupon === 'success' ? '#AFE1B1' : undefined,
          borderRadius: 1,
          width: '100%',
          color: colors.darkgrey,
          padding: 2,
        }}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          sx={{ paddingLeft: 5, paddingRight: 5 }}>
          <Grid item>
            <TextField
              disabled={validCoupon === 'success'}
              value={coupon}
              onChange={e =>
                setCoupon(e.target.value.toUpperCase().replace(' ', ''))
              }
              fullWidth
              error={validCoupon === 'error'}
              helperText={validCoupon === 'error' ? 'Ugyldig kuppong' : ''}
              size='small'
              placeholder='Skriv inn rabattkode'
            />
          </Grid>
          <Grid item>
            <Grid container direction='column' alignItems='center'>
              <Button
                disabled={!coupon || validCoupon === 'success'}
                onClick={onClick}
                sx={{ ...buttonStyle, boxShadow: colors.shadow }}
                size='small'
                style={{
                  height: 50,
                  width: 200,
                  fontSize: 18,
                }}
                variant='contained'>
                {discountLoading ? (
                  <CircularProgress />
                ) : validCoupon === 'success' ? (
                  'Rabattkode aktivert'
                ) : (
                  'Aktiver rabattkode'
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default CouponCard
