import { Grid, Grow } from '@mui/material'
import AvailableSessions from './AvailableSessions'
import { SessionType } from '../../../helpers/apiResponsFormatter'
import { useContext } from 'react'
import WorkoutContext from '../../../Contexts/WorkoutContext'
import { smallTextStyles } from '../../../themes/generalStyles'

const PickAvailableSlot = ({
  notSelectedWorkouts,
  singleLevelType,
}: {
  notSelectedWorkouts: SessionType[]
  singleLevelType: boolean
}) => {
  const { selectedLevel } = useContext(WorkoutContext)
  return (
    <div
      style={{
        width: 250,
        marginTop: 20,
      }}>
      {!singleLevelType ? (
        <Grid
          container
          direction='row'
          justifyContent='center'
          gap={4}
          alignItems='center'>
          <div>
            <AvailableSessions
              mod='level'
              workoutSessions={notSelectedWorkouts}
              headerText='Nivå'
            />
          </div>
          {selectedLevel && (
            <Grow in unmountOnExit timeout={1000}>
              <div>
                <AvailableSessions
                  mod='time'
                  workoutSessions={notSelectedWorkouts}
                  headerText='Tider'
                />
              </div>
            </Grow>
          )}
        </Grid>
      ) : (
        <Grid container justifyContent='center'>
          <div>
            <AvailableSessions
              singleLevelType={true}
              mod='time'
              workoutSessions={notSelectedWorkouts}
              headerText='Tider'
            />
          </div>
        </Grid>
      )}
    </div>
  )
}

export default PickAvailableSlot
