import { Grid, IconButton, Menu, MenuItem } from '@mui/material'
import { Toc } from '@mui/icons-material'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import ModalContext from '../Contexts/ModalContext'
import UserContext from '../Contexts/UserContext'
import NewsTag from './NewsTag'
import Link from '../Utils/Link'
import BackButton from '../Utils/BackButton'
import { useLocation } from 'react-router-dom'
import screenScaleContext from '../Contexts/ScreenScaleContext'
import { colors } from '../themes/generalStyles'

const MenuTab = ({
  isBasePath,
  menuOpen,
  menuComponent,
  onMenuClose,
  setMenuComponent,
}: {
  isBasePath: boolean
  menuOpen: boolean
  menuComponent: any
  onMenuClose: () => void
  setMenuComponent: Dispatch<SetStateAction<any>>
}) => {
  const { loggedIn } = useContext(UserContext)
  const { smallScreenWidth } = useContext(screenScaleContext)
  const { setOpenModal } = useContext(ModalContext)

  return (
    <div style={{ margin: 0 }}>
      <Grid container alignItems='center' justifyContent='space-between'>
        {!isBasePath && (
          <div style={{ marginRight: 20 }}>
            <Link to='..'>
              <BackButton onClick={() => null} />
            </Link>
          </div>
        )}
        {!smallScreenWidth && <NewsTag />}
        <IconButton
          style={{
            width: 70,
            height: 70,
            margin: 0,
            padding: 0,
          }}
          onClick={e => setMenuComponent(e.currentTarget)}>
          <Grid container direction='column' alignItems='center'>
            <Toc
              style={{
                marginTop: 5,
                height: 60,
                width: 70,
                padding: 0,
                margin: 0,
              }}
            />
            <span style={{ fontSize: 14, transform: 'translate(0px,-15px)' }}>
              Meny
            </span>
          </Grid>
        </IconButton>
      </Grid>
      <Menu
        id='some unique id'
        open={menuOpen}
        anchorEl={menuComponent}
        onBlur={onMenuClose}>
        <Link to='/'>
          <MenuItem style={{ height: 50, fontSize: 20 }}>Din ukeplan</MenuItem>
        </Link>
        <Link to='/info-økter'>
          <MenuItem style={{ height: 50, fontSize: 20 }}>
            Om treningstilbudet
          </MenuItem>
        </Link>
        <MenuItem
          style={{ height: 50, fontSize: 20 }}
          onClick={() => setOpenModal('subscriptions')}>
          Medlemskap og priser
        </MenuItem>
        {/*<Link to='/ukeplan'>*/}
        {/*  <MenuItem style={{ height: 50, fontSize: 20 }}>*/}
        {/*    Gruppetimeoversikt*/}
        {/*  </MenuItem>*/}
        {/*</Link>*/}
        <Link to='/nettbutikk'>
          <MenuItem style={{ height: 50, fontSize: 20 }}>Nettbutikk</MenuItem>
        </Link>
        <Link to='/min-side'>
          <MenuItem style={{ height: 50, fontSize: 20 }} disabled={!loggedIn}>
            Min side {!loggedIn && '(Du må logge inn først)'}
          </MenuItem>
        </Link>
        <MenuItem
          style={{ height: 50, fontSize: 20 }}
          onClick={() => setOpenModal('contactUs')}>
          Kontakt oss
        </MenuItem>
        <Link to='/om-oss'>
          <MenuItem style={{ height: 50, fontSize: 20 }}>Om oss</MenuItem>
        </Link>
        <Link to='/nyheter'>
          <MenuItem style={{ height: 50, fontSize: 20 }}>Nyheter</MenuItem>
        </Link>
        <Link to='/videos'>
          <MenuItem style={{ height: 50, fontSize: 20 }}>
            Videobibliotek
          </MenuItem>
        </Link>
        <Grid container justifyContent='flex-end'>
          <Link to='/terms'>
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                marginRight: 10,
              }}>
              Juridisk
            </span>
          </Link>
        </Grid>
      </Menu>
    </div>
  )
}

export default MenuTab
