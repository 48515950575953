import { Button, CircularProgress, Grid } from '@mui/material'
import ModalWrapper from './ModalWrapper'
import SubscriptionCard from '../Utils/SubscriptionCard'
import { useContext, useEffect, useState } from 'react'
import useApi from '../hooks/useApi'
import { fetchDiscount, getSubscriptions } from '../api/fetch'
import { Subscription } from '../helpers/apiResponsFormatter'
import { isEmpty, orderBy } from 'lodash'
import userContext from '../Contexts/UserContext'
import modalContext from '../Contexts/ModalContext'
import { buttonSuccessStyle } from '../themes/generalStyles'
import useRouteToStripe from '../hooks/useRouteToStripe'
import settings from '../config'
import CouponCard from '../Utils/CouponCard'

const BottomSubscriptionConditions = () => {
  return (
    <div>
      <ul>
        <li>Alle medlemsskapene gir full tilgang</li>
        <li style={{ marginTop: 20 }}>
          Det er ingen bindingstid til tjenesten
        </li>
        <li style={{ marginTop: 20 }}>
          Spar penger ved å betale for flere måneder
        </li>
        <li style={{ marginTop: 20 }}>
          30 dager gratis prøveperiode for nye kunder blir lagt til ved utsjekk
        </li>
      </ul>
    </div>
  )
}

const Subscriptions = () => {
  const { userData } = useContext(userContext)
  const { openModal } = useContext(modalContext)
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const { doFetch, isLoading } = useApi(getSubscriptions)
  const [newCustomerView, setNewCustomerView] = useState('')
  const [rubberBandId, setRubberBandId] = useState('')
  const [coupon, setCoupon] = useState('')
  const [discountState, setDiscountState] = useState<
    'idle' | 'success' | 'error'
  >('idle')
  const {
    data: discountData,
    doFetch: discountFetch,
    isLoading: discountLoading,
  } = useApi(fetchDiscount)
  const discount = (discountData?.discount || 1) as number
  const stripeAPI = useRouteToStripe()
  const error = stripeAPI.isError
  useEffect(() => {
    if (error) {
      setCoupon('')
      setNewCustomerView('')
    }
  }, [error])

  useEffect(() => {
    if (subscriptions.length === 0 && openModal === 'subscriptions') {
      doFetch({
        data: {},
        onSuccess: data => setSubscriptions(data.subscriptions),
      })
    }
    setNewCustomerView('')
  }, [openModal])
  const subscriptionToView = subscriptions.filter(
    item => !item.name.includes('treningsstrikker'),
  )
  const { subToView, rubberBandOffer } = subscriptions.reduce(
    (result, sub) => {
      if (sub.name.includes('treningsstrikker')) {
        result.rubberBandOffer = sub
      } else {
        result.subToView.push(sub)
      }
      return result
    },
    { subToView: [], rubberBandOffer: null } as {
      subToView: Subscription[]
      rubberBandOffer: null | Subscription
    },
  )
  const notUsedFreeTrial = !!(userData && !userData.usedFreeTrial)
  const orderedSubscriptions = orderBy(subToView, 'price', ['desc'])
  const first = orderedSubscriptions[0] || null

  const onCouponActivateClick = () => {
    discountFetch({
      data: { coupon },
      onError: () => setDiscountState('error'),
      onSuccess: () => setDiscountState('success'),
    })
  }

  return (
    <ModalWrapper
      header={newCustomerView ? 'Ny kunde' : 'Medlemskap og priser'}
      openModalKey='subscriptions'>
      <>
        {error && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: 'red',
            }}>
            Noe gikk galt.
          </div>
        )}
        <Grid container sx={{ maxWidth: 800 }}>
          {newCustomerView ? (
            <div style={{ fontSize: 20, textAlign: 'center' }}>
              <div>
                Ved aktivering av nytt medlemskap starter din gratis
                prøveperiode på 30 dager, og du får tilsendt to
                treningsstrikker. Etter prøveperioden fornyes medlemskapet
                automatisk
                {discount !== 1
                  ? ` med ${Math.round((1 - discount) * 100)}% rabatt.`
                  : '.'}
              </div>
              <div style={{ marginTop: 30 }}>
                {discount !== 1
                  ? 'Med gyldig rabattkode får du gratis innmeldingsavgift og to treningsstrikker uten kostnad'
                  : 'Innmeldingsavgift på 89,- dekker frakt av strikkene.'}
              </div>
              <Grid item>
                <Grid container justifyContent='center'>
                  <img
                    style={{ width: 300 }}
                    src={`${settings.bucket}/root_images/signupImage.png`}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent='center' sx={{ marginTop: 2 }}>
                <Button
                  sx={{ ...buttonSuccessStyle, fontSize: 20 }}
                  onClick={() =>
                    stripeAPI.buttonOnClick(
                      false,
                      newCustomerView,
                      rubberBandId,
                      coupon,
                    )
                  }>
                  Gå videre til utsjekking
                </Button>
              </Grid>
            </div>
          ) : (
            <>
              <Grid container gap={2} justifyContent='center'>
                {!isEmpty(subToView) ? (
                  orderedSubscriptions.map((item, key) => (
                    <Grid key={key} xs={12} item>
                      <SubscriptionCard
                        coupon={coupon}
                        apis={stripeAPI}
                        setNewCustomerView={setNewCustomerView}
                        notUsedFreeTrial={notUsedFreeTrial}
                        bindingPeriod={Number(item.binding_period)}
                        id={item.id}
                        cost={Math.ceil(item.price * discount)}
                        rubberBandId={rubberBandId}
                      />
                    </Grid>
                  ))
                ) : (
                  <CircularProgress size={50} />
                )}
                {!isEmpty(subToView) && (
                  <CouponCard
                    discountLoading={discountLoading}
                    coupon={coupon}
                    setCoupon={setCoupon}
                    onClick={onCouponActivateClick}
                    validCoupon={discountState}
                  />
                )}
              </Grid>
              <BottomSubscriptionConditions />
            </>
          )}
        </Grid>
      </>
    </ModalWrapper>
  )
}

export default Subscriptions
