import { Box, Button, Grid, Modal } from '@mui/material'
import {
  buttonDisabledStyle,
  buttonMainStyle,
  colors,
  font,
} from '../../../themes/generalStyles'
import settings from '../../../config'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import LaunchIcon from '@mui/icons-material/Launch'
import { useState } from 'react'

const UserManualModalWithButton = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Button sx={buttonMainStyle} onClick={() => setIsOpen(true)}>
        <PictureAsPdfIcon />
        Se og last ned
        <LaunchIcon />
      </Button>
      <Modal
        onClose={() => setIsOpen(false)}
        open={isOpen}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box>
          <Grid
            sx={{
              padding: 5,
              backgroundColor: colors.lightGray,
              width: '70vw',
              border: `2px solid ${colors.darkgrey}`,
              borderRadius: 2,
              ...font,
            }}
            container
            justifyContent='space-between'
            alignItems='center'
            columns={1}>
            <Grid container justifyContent='space-between'>
              <h2 style={{ marginTop: 0 }}>Brukermanual</h2>
              <Button
                sx={{ ...buttonDisabledStyle, height: 40 }}
                onClick={() => setIsOpen(false)}>
                Lukk
              </Button>
            </Grid>
            <iframe
              title='Medlemskapvilkår'
              style={{ width: '100%', height: '70vh' }}
              src={`${settings.bucket}/legal/userManual.pdf`}
            />
          </Grid>
        </Box>
      </Modal>
    </>
  )
}

export default UserManualModalWithButton
