import TopNav from './TopNav/TopNav'
import Modals from './Modals/Modals'
import { ModalContextProvider } from './Contexts/ModalContext'
import BodyWrapper from './Utils/BodyWrapper'
import baseColors from './themes/baseColors'
import MainPage from './Views/MainPage/MainPage'
import BottomBar from './BottomBar/BottomBar'
import { UserContextProvider } from './Contexts/UserContext'
import { useState } from 'react'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import CreateUser from './Views/CreateUser/CreateUser'
import ReserveCalendlyBooking from './Views/ReserveCalendlyBooking/ReserveCalendlyBooking'
import CalendlyAdd from './Views/ReserveCalendlyBooking/CalendlyAdd'
import AboutUs from './Views/AboutUs/AboutUs'
import MyPage from './Views/MyPage/MyPage'
import WorkoutInfo from './Views/WorkoutInfo/WorkoutInfo'
import News from './Views/News/News'
import WebShop from './Views/WebShop/WebShop'
import StripeValidationPage from './Views/StripeValidationPage/StripeValidationPage'
import Terms from './Views/Terms/Terms'
import Schedule from './Views/Schedule/Schedule'
import NoContent from './Views/NoContent/NoContent'
import UserTerms from './Views/Terms/UserTerms'
import CookieTerms from './Views/Terms/CookieTerms'
import { ScreenScaleContextProvider } from './Contexts/ScreenScaleContext'
import VideoContainer from './Views/VideoStream/VideoContainer'
import Videos from './Views/Videos/Videos'

const App = (): JSX.Element => {
  // @ts-ignore
  document.body.bgColor = baseColors.backgroundColor
  const [loadingPage, setLoadingPage] = useState(true)
  return (
    <UserContextProvider setLoadingPage={setLoadingPage}>
      <ModalContextProvider>
        <ScreenScaleContextProvider>
          <>
            <BrowserRouter>
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <TopNav />
                <Modals />
                <BodyWrapper>
                  <>
                    <Routes>
                      <Route
                        path='/'
                        element={<MainPage loadingPage={loadingPage} />}
                      />
                      <Route path='/ny-bruker' element={<CreateUser />} />
                      <Route
                        path='/calendly-booking'
                        element={<ReserveCalendlyBooking />}
                      />
                      <Route
                        path='/calendly-booking-ny'
                        element={<CalendlyAdd />}
                      />
                      <Route path='/om-oss' element={<AboutUs />} />
                      <Route path='/min-side' element={<MyPage />} />
                      <Route path='/info-økter' element={<WorkoutInfo />} />
                      <Route path='/nyheter' element={<News />} />
                      <Route path='/nettbutikk' element={<WebShop />} />
                      <Route path='/terms' element={<Terms />} />
                      <Route path='/user-terms' element={<UserTerms />} />
                      <Route path='/cookie-terms' element={<CookieTerms />} />
                      <Route
                        path='/nettbutikk-bekreftelse'
                        element={<StripeValidationPage />}
                      />
                      {/*<Route path='/ukeplan' element={<Schedule />} />*/}
                      <Route path='/session' element={<VideoContainer />} />
                      <Route path='/videos' element={<Videos />} />
                      <Route path='/*' element={<NoContent />} />
                    </Routes>
                  </>
                </BodyWrapper>
              </QueryParamProvider>
              {!loadingPage && <BottomBar />}
            </BrowserRouter>
          </>
        </ScreenScaleContextProvider>
      </ModalContextProvider>
    </UserContextProvider>
  )
}

export default App
