import MainCard from '../MainCard'
import { Box, Button, Grid, Modal } from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import LaunchIcon from '@mui/icons-material/Launch'
import { buttonMainStyle, colors, font } from '../../../themes/generalStyles'
import HeaderLine from '../../../Utils/HeaderLine'
import settings from '../../../config'
import axios from 'axios'
import UserManualModalWithButton from './UserManualModalWithButton'

const UserManualDownload = () => {
  const downloadManual = async () => {
    try {
      const url = `${settings.bucket}/legal/userManual.pdf`
      const response = await axios.get(url, {
        responseType: 'blob',
        headers: { 'Access-Control-Allow-Origin': '*' },
      })
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'your-file.pdf'
      link.click()
      URL.revokeObjectURL(link.href) // Clean up
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }
  return (
    <>
      <MainCard sx={{ width: '100%', minHeight: 200 }}>
        <div>
          <h2 style={{ textAlign: 'center' }}>
            Ønsker du mer utfyllende informasjon om tjenesten
          </h2>
          <HeaderLine styles={{ marginBottom: 5 }} />
          <Grid container justifyContent='space-around'>
            <Grid item>
              <div>Last ned vår brukermanual som PDF</div>
            </Grid>
            <Grid item>
              <UserManualModalWithButton />
            </Grid>
          </Grid>
        </div>
      </MainCard>
    </>
  )
}

export default UserManualDownload
