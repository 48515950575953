import ModalWrapper from './ModalWrapper'
import { useContext, useState } from 'react'
import { buttonSuccessStyle } from '../themes/generalStyles'
import { Button, CircularProgress, Grid, TextField } from '@mui/material'
import userContext from '../Contexts/UserContext'
import useApi from '../hooks/useApi'
import { contactUs } from '../api/fetch'
import modalContext from '../Contexts/ModalContext'
import { isEmpty } from 'lodash'
import screenScaleContext from '../Contexts/ScreenScaleContext'

const ContactUs = () => {
  const { loggedIn, userData } = useContext(userContext)
  const { smallScreenWidth } = useContext(screenScaleContext)
  const [text, setText] = useState('')
  const [mail, setMail] = useState('')
  const [name, setName] = useState('')
  const buttomDisabled = !text || !mail || !name
  const { isLoading, doFetch, data } = useApi(contactUs)
  return (
    <ModalWrapper
      header='Kontakt oss'
      openModalKey='contactUs'
      styles={{ maxWidth: 800 }}>
      {isEmpty(data) ? (
        <div>
          <h4>Telefon: +47 22 34 33 80</h4>
          <h4> Åpent mellom 11:00-15:00 (mandag til fredag)</h4>
          {!loggedIn && (
            <>
              <Grid
                container
                justifyContent='space-between'
                gap={1}
                sx={{ marginTop: -2 }}>
                <Grid item xs={smallScreenWidth ? 12 : 6}>
                  <TextField
                    label='Navn'
                    fullWidth
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={smallScreenWidth ? 12 : 5.5}>
                  <TextField
                    label='Epost'
                    fullWidth
                    value={mail}
                    onChange={e => setMail(e.target.value)}
                  />
                </Grid>
              </Grid>
              <br />
            </>
          )}
          <TextField
            rows={4}
            multiline
            fullWidth
            label='Skriv hva det gjelder så tar vi kontakt'
            id='fullWidth'
            value={text}
            onChange={e => setText(e.target.value)}
          />
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: 20,
            }}>
            <Button
              onClick={() =>
                doFetch({
                  data: {
                    hasUser: loggedIn,
                    mail: loggedIn && userData ? userData.emailAddress : mail,
                    name:
                      loggedIn && userData
                        ? `${userData.firstName} ${userData.lastName}`
                        : name,
                    message: text,
                  },
                })
              }
              style={{ fontSize: 18 }}
              sx={buttonSuccessStyle}
              disabled={loggedIn ? isEmpty(text) : buttomDisabled}>
              {isLoading ? <CircularProgress size={30} /> : 'Send melding'}
            </Button>
          </span>
        </div>
      ) : (
        <Grid container justifyContent='center'>
          <h1>Din henvendelse er mottat</h1>
          <h4>Vi vil komme tilbake til deg så snart vi kan</h4>
        </Grid>
      )}
    </ModalWrapper>
  )
}

export default ContactUs
