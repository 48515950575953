import { Box } from '@mui/material'
import ReactPlayer from 'react-player'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { colors } from '../../themes/generalStyles'
import { Dispatch, SetStateAction, useContext } from 'react'
import ScreenScaleContext from '../../Contexts/ScreenScaleContext'
import { VideoType } from './Videos'

const VideoBox = ({ video }: { video: VideoType }) => {
  const { smallScreenWidth } = useContext(ScreenScaleContext)
  return (
    <Box sx={{ width: '100%', padding: smallScreenWidth ? 0 : 2 }}>
      <h3 style={{ textAlign: 'center', marginBottom: 10 }}>{video.title}</h3>
      <Box
        sx={{
          padding: 1,
          borderRadius: 2,
          width: '100%',
          boxShadow: colors.shadow,
          aspectRatio: '16/9',
          backgroundColor: colors.lightGray,
          transition: '0.5s',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}>
        <ReactPlayer
          width='100%'
          height='100%'
          style={{ width: '100%', height: '100%' }}
          url={video.url}
          controls
          light={true}
          config={{
            vimeo: {
              playerOptions: {
                cc: false,
                responsive: true,
                title: true,
                vimeo_logo: false,
                volume: false,
                transcript: false,
              },
            },
          }}
          playIcon={
            <PlayCircleOutlineIcon
              sx={{ fontSize: 70, color: colors.disabled }}
            />
          }
        />
      </Box>
    </Box>
  )
}

export default VideoBox
