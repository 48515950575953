import { useContext, useEffect, useState } from 'react'
import Video from './Video'
import { VideoContextProvider } from '../../Contexts/VideoContext'
import { useSearchParams } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import { fetchMeetingUrl } from '../../api/fetch'
import userContext from '../../Contexts/UserContext'

const VideoContainer = () => {
  const { userData } = useContext(userContext)
  const { data, doFetch } = useApi(fetchMeetingUrl)
  const wherebyUrl = data?.url
  const [searchParams, setSearchParams] = useSearchParams()
  const sessionId = searchParams.get('sessionId')
  useEffect(() => {
    if (sessionId && Number(sessionId)) {
      doFetch({
        data: { sessionId: sessionId },
      })
    }
  }, [sessionId])
  return (
    <div style={{ marginTop: 0 }}>
      {userData && userData.validSubscription && wherebyUrl ? (
        <VideoContextProvider
          wherebyUrl={wherebyUrl}
          name={`#${userData?.customerId || 'Nan'}`}>
          <Video />
        </VideoContextProvider>
      ) : (
        <div>Laster video...</div>
      )}
    </div>
  )
}

export default VideoContainer
