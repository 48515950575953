import MainCard from '../MainPage/MainCard'
import { useState } from 'react'
import { Grid } from '@mui/material'
import PageOne from './PageOne/PageOne'
import { CreateUserContextProvider } from '../../Contexts/CreateUserContext'
import Progress from './Progress'
import PageTwo from './pageTwo/PageTwo'
import CreateUserNavigationButtons from './CreateUserNavigationButtons'
import PageFour from './PageFour/PageFour'
import WelcomeToPage from './WelcomeToPage'
import { font, standardWidthLarger } from '../../themes/generalStyles'

const CreateUser = () => {
  const [page, setPage] = useState(0)
  const [isUser, setIsUser] = useState(false)
  return (
    <CreateUserContextProvider setIsUser={setIsUser}>
      <div style={{ marginBottom: 30, ...font }}>
        <MainCard
          sx={{ maxWidth: standardWidthLarger }}
          cardFooter={
            <CreateUserNavigationButtons
              page={page}
              setPage={setPage}
              isUser={isUser}
            />
          }>
          <>
            <Grid container justifyContent='center'>
              <div>
                <h1>Opprett Bruker</h1>
              </div>
            </Grid>
            <Progress page={page} setPage={setPage} />
            <div style={{ padding: 40 }}>
              {page === 0 && <PageOne />}
              {page === 1 && <PageTwo />}
              {page === 2 && (isUser ? <WelcomeToPage /> : <PageFour />)}
            </div>
          </>
        </MainCard>
      </div>
    </CreateUserContextProvider>
  )
}

export default CreateUser
