import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { colors } from '../../themes/generalStyles'

const VideoFilter = ({
  tags,
  filter,
  setFilter,
}: {
  tags: string[]
  filter: string
  setFilter: Dispatch<SetStateAction<string>>
}) => {
  return (
    <FormControl variant='filled' sx={{ m: 1, minWidth: 120 }}>
      <InputLabel
        id='VideoFilter'
        sx={{
          color: colors.main,
        }}>
        Filtrer videoer etter søkeord:
      </InputLabel>
      <Select
        sx={{
          borderRadius: 2,
          width: 300,
          '&:before': {
            borderColor: colors.main,
          },
          '&:after': {
            borderColor: colors.main,
          },
        }}
        value={filter}
        labelId='video-filter'
        id='video-filter'
        label='Video filter'>
        <MenuItem value='Alle' onClick={() => setFilter('Alle')}>
          Alle
        </MenuItem>
        {tags.map((tag, key) => (
          <MenuItem key={key} value={tag} onClick={() => setFilter(tag)}>
            {tag}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default VideoFilter
