import { colors, font } from '../../themes/generalStyles'
import { Box, Grid, Modal } from '@mui/material'
import { Dispatch, ReactNode, SetStateAction, useContext } from 'react'
import settings from '../../config'
import ScreenScaleContext from '../../Contexts/ScreenScaleContext'

const ModalBase = ({
  isOpen,
  setIsOpen,
  children,
}: {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  children: ReactNode
}) => {
  return (
    <Modal
      onClose={() => setIsOpen(false)}
      open={isOpen}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box>
        <Grid
          sx={{
            overflow: 'auto',
            padding: 5,
            backgroundColor: colors.lightGray,
            width: '70vw',
            border: `2px solid ${colors.darkgrey}`,
            height: '80vh',
            borderRadius: 2,
            ...font,
          }}>
          {children}
        </Grid>
      </Box>
    </Modal>
  )
}

const TermsModal = ({
  privacy,
  isOpen,
  setIsOpen,
}: {
  privacy: boolean
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { smallScreenWidth } = useContext(ScreenScaleContext)
  return (
    <ModalBase isOpen={isOpen} setIsOpen={setIsOpen}>
      <div style={{ overflow: 'auto' }}>
        {privacy ? (
          <>
            <h1 style={{ textAlign: 'center' }}>iSteady Personvern</h1>
            <iframe
              title='Personvern'
              style={{ width: '100%', height: '50vh' }}
              src={`${settings.bucket}/legal/privacyTerms.pdf`}
            />
          </>
        ) : (
          <>
            <h1 style={{ textAlign: 'center' }}>
              iSteady <br />
              {smallScreenWidth ? 'Medlemskap - vilkår' : 'Medlemskapvilkår'}
            </h1>
            <iframe
              title='Medlemskapvilkår'
              style={{ width: '100%', height: '50vh' }}
              src={`${settings.bucket}/legal/membershipTerms.pdf`}
            />
            <h1 style={{ textAlign: 'center' }}>
              iSteady{' '}
              {smallScreenWidth ? 'Nettbutikk - vilkår' : 'Nettbutikkvilkår'}
            </h1>
            <iframe
              title='Nettbutikkvilkår'
              style={{ width: '100%', height: '50vh' }}
              src={`${settings.bucket}/legal/webShopTerms.pdf`}
            />
          </>
        )}
      </div>
    </ModalBase>
  )
}

export default TermsModal
