import { Button, CardHeader, Grid } from '@mui/material'
import StrongAndSteadyInfo from '../MainPage/Workouts/StrongAndSteadyInfo'
import ShoulderAndKneesInfo from '../MainPage/Workouts/ShoulderAndKneesInfo'
import MainCard from '../MainPage/MainCard'
import {
  buttonSuccessStyle,
  standardWidthLarger,
} from '../../themes/generalStyles'
import TopLogoCard from '../MainPage/TopLogoCard'
import { useContext } from 'react'
import screenScaleContext from '../../Contexts/ScreenScaleContext'
import sessionNames from '../../constants'
import HeaderLine from '../../Utils/HeaderLine'
import config from '../../config'
import Link from '../../Utils/Link'

const WorkoutInfo = () => {
  const { smallScreenWidth } = useContext(screenScaleContext)
  return (
    <MainCard
      cardHeader={
        <CardHeader
          title={
            <Grid container>
              <TopLogoCard
                backToButton='/'
                sx={{ width: '100%' }}
                content={
                  smallScreenWidth
                    ? 'Om trenings-tilbudet'
                    : 'Om treningstilbudet'
                }
              />
            </Grid>
          }
        />
      }
      sx={{ maxWidth: standardWidthLarger, marginBottom: 10 }}>
      <>
        <MainCard>
          <div style={{ padding: 20 }}>
            <div>
              iSteady tilbyr interaktive gruppetimer med faste tidspunkter hver
              uke, som vist i oversikten nedenfor. Gruppene ledes av
              instruktører i sanntid, som gir deg muligheten til å følge
              demonstrasjoner, motta veiledning og få korrigeringer underveis.
            </div>
            <br />
            <div>
              Dette gir en mer engasjerende og strukturert treningsopplevelse,
              selv når du trener hjemmefra. Som deltaker ser du kun instruktøren
              og deg selv, mens instruktøren ser alle deltakerne.
            </div>
            <br />
            <div>
              I tillegg har vi et bibliotek med ferdiginnspilte treningsvideoer
              og mer, som du kan bruke når det passer deg.
            </div>
          </div>
        </MainCard>
        <Grid container justifyContent='center' sx={{ marginTop: 2 }}>
          <h2>Oversikt over interaktive gruppetimer med instruktør</h2>
        </Grid>
        <Grid container justifyContent='center' gap={2}>
          <StrongAndSteadyInfo />
          <ShoulderAndKneesInfo
            selectedWorkout={sessionNames['strongShoulders']}
          />
          <ShoulderAndKneesInfo selectedWorkout={sessionNames['strongKnees']} />
        </Grid>
        <HeaderLine styles={{ marginTop: 5, marginBottom: 5 }} />
        <MainCard>
          <div>
            <Grid container justifyContent='center'>
              <h2>Tidspunkter for våre interaktive gruppetimer</h2>
            </Grid>
            <Grid container columns={2} justifyContent='space-around'>
              <div>
                <Grid
                  container
                  columns={1}
                  direction='column'
                  gap={2}
                  alignItems='center'>
                  <Grid item>
                    <strong>Mandag</strong>
                  </Grid>
                  <Grid item>
                    <img
                      style={{ width: 400, height: 'auto' }}
                      src={`${config.bucket}/root_images/https://isteadyqabucket.ams3.cdn.digitaloceanspaces.com/root_images/mandags_program.png`}
                    />
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid
                  container
                  columns={1}
                  direction='column'
                  gap={2}
                  alignItems='center'>
                  <Grid item>
                    <strong>Onsdag</strong>
                  </Grid>
                  <Grid item>
                    <img
                      style={{ width: 400, height: 'auto' }}
                      src={`${config.bucket}/root_images/https://isteadyqabucket.ams3.cdn.digitaloceanspaces.com/root_images/onsdag_program.png`}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </div>
        </MainCard>
        <HeaderLine styles={{ marginTop: 5, marginBottom: 5 }} />
        <MainCard>
          <div>
            <Grid container direction='column' alignItems='center' gap={3}>
              <h2>Ferdiginnspilte Treningsvideoer</h2>
              <div>
                Vi har også ferdiginspilte videoer av våre gruppetimer og mer
                som du kan se når det passer deg.
              </div>
              <div>
                <Link to='../videos'>
                  <Button sx={{ ...buttonSuccessStyle }}>
                    Gå til videobibliotek
                  </Button>
                </Link>
              </div>
            </Grid>
          </div>
        </MainCard>
      </>
    </MainCard>
  )
}

export default WorkoutInfo
