import { colors } from '../../../themes/generalStyles'
import { Grid } from '@mui/material'
import settings from '../../../config'
import MainCard from '../MainCard'

const InstructorInfoCard = () => {
  return (
    <Grid container>
      <MainCard
        sx={{
          width: '100%',
        }}>
        <Grid container justifyContent='center'>
          <h2 style={{ textAlign: 'center', width: '100%', maxWidth: 720 }}>
            Treningstimene skjer i sanntid og ledes av instruktører på faste
            ukentlige tidspunkter
          </h2>
          <img
            alt='iSteady hands out image'
            src={`${settings.bucket}/root_images/shoulderSitting.jpg`}
            style={{
              width: '90%',
              maxWidth: 700,
              height: 'auto',
              boxShadow: colors.shadow,
              borderRadius: 5,
            }}
          />
        </Grid>
      </MainCard>
    </Grid>
  )
}

export default InstructorInfoCard
