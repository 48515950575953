import { Button, Grid } from '@mui/material'
import MainCard from '../MainCard'
import HeaderLine from '../../../Utils/HeaderLine'
import settings from '../../../config'
import {
  buttonMainStyle,
  buttonSuccessStyle,
  colors,
} from '../../../themes/generalStyles'
import { useState } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

const SingleStep = ({
  header,
  body,
  image,
}: {
  header: string
  body: string
  image: string
}) => {
  return (
    <Grid container sx={{ width: '100%' }} alignItems='center'>
      <Grid
        item
        xs={2}
        sx={{
          // backgroundColor: 'blue',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}>
        <div>
          <img
            alt={image}
            src={`${settings.bucket}/root_images/${image}`}
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </div>
      </Grid>
      <Grid item xs={10} sx={{ paddingLeft: 5 }}>
        <div>
          <h3 style={{ marginBottom: 5, marginTop: 0, width: '100%' }}>
            {header}
          </h3>
          <div>{body}</div>
        </div>
      </Grid>
    </Grid>
  )
}

const TenStep = () => {
  const [expanded, setExpanded] = useState(false)
  return (
    <MainCard sx={{ width: '100%' }}>
      <div style={{ padding: 10 }}>
        <h2 style={{ textAlign: 'center' }}>
          10 enkle trinn for å mestre tjenesten
        </h2>
        <HeaderLine styles={{ marginBottom: 2 }} />
        {expanded && (
          <Grid container columns={1} gap={2}>
            <SingleStep
              header='Opprett bruker på 1-2-3'
              body='Du kan enkelt opprette en bruker ved å følge noen få trinn. Når du har opprettet en bruker, får du tilgang til din egen ukeplan og brukerside, noe som gir deg en personlig opplevelse og gjør det enklere å holde oversikt over dine aktiviteter.'
              image='step1.jpg'
            />

            <SingleStep
              header='Enkel innlogging med telefonnummer'
              body='Du logger deg enkelt inn på brukeren din med telefonnummeret ditt. Du mottar en engangskode på SMS som du bruker for å logge deg inn, og slipper dermed å huske passord og brukernavn.'
              image='step2.jpg'
            />

            <SingleStep
              header='Aktiver medlemskap for full tilgang'
              body='For å få full tilgang til tjenesten, må du aktivere et gyldig medlemskap. Dette gir deg muligheten til å melde deg på live gruppetimer og få tilgang til vårt videobibliotek med ferdiginnspilte treningsvideoer og mer.'
              image='step3.jpg'
            />

            <SingleStep
              header='Gratis prøveperiode for nye kunder'
              body='Alle nye kunder får en 30-dagers gratis prøveperiode som aktiveres ved utsjekk når medlemskapet opprettes. Prøveperioden starter ved aktivering av medlemskapet, og etter prøveperioden fornyes medlemskapet automatisk.'
              image='step4.jpg'
            />

            <SingleStep
              header='Bruk rabattkoden din'
              body='Dersom du har en rabattkode, kan du legge den inn når du aktiverer medlemskapet. Dette gir deg muligheten til å få tilgang til alle fordelene med medlemskapet til en ekstra gunstig pris eller med spesielle fordeler.'
              image='step5.jpg'
            />

            <SingleStep
              header='Skreddersy din egen ukeplan'
              body='Meld deg på live gruppetimer og skreddersy din egen ukeplan. Når du har meldt deg på en gruppetime, forblir påmeldingen aktiv til du selv velger å melde deg av. Det er ikke nødvendig å gi beskjed dersom du en gang blir forhindret fra å delta.'
              image='step6.jpg'
            />

            <SingleStep
              header='SMS - varsling'
              body='På dager hvor du er påmeldt en live gruppetime, vil du motta en SMS-varsling tidlig på dagen som en påminnelse. Dette hjelper deg med å holde oversikt, slik at du ikke går glipp av noen av dine planlagte aktiviteter'
              image='step7.jpg'
            />

            <SingleStep
              header='Bli med på live trening: Klar, Ferdig, Gå!'
              body='For å delta i live gruppetimen, klikk på “Bli med på trening”-knappen, som blir aktiv noen minutter før timen starter. Du vil da bli sendt direkte til videomøtet. Husk å gi nettleseren tillatelse til å bruke kamera og mikrofon for å få fullt utbytte av timen'
              image='step8.jpg'
            />

            <SingleStep
              header='Tren når det passer deg'
              body='Hvis du ikke rekker live-timen eller ønsker å trene på et annet tidspunkt, finner du flere ferdiginnspilte gruppetimer i videobiblioteket. Dette gir deg friheten til å trene når det passer deg best.'
              image='step9.jpg'
            />

            <SingleStep
              header='Kansellering av medlemskap'
              body='Vi tilbyr medlemskap uten bindingstid for å gi deg full fleksibilitet. Når du melder deg av, kan du fortsatt bruke tjenesten ut perioden du har betalt for, før tilgangen stenges. Du kan avslutte din 30-dagers gratis prøveperiode før måneden er over for å unngå å bli belastet for neste måned. '
              image='step10.jpg'
            />
          </Grid>
        )}
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button sx={buttonMainStyle} onClick={() => setExpanded(!expanded)}>
            {expanded ? 'Minimer' : 'Les mer'}
            {expanded ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </Button>
        </div>
      </div>
    </MainCard>
  )
}

export default TenStep
