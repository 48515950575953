import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import { useContext, useState } from 'react'
import createUserContext from '../../../Contexts/CreateUserContext'
import TextInputField from '../../../Utils/TextInputField'
import HouseIcon from '@mui/icons-material/House'
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import { colors } from '../../../themes/generalStyles'
import TermsModal from '../TermsModal'

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  return emailRegex.test(email)
}

export const phoneNumberValidation = (phoneNumber: string): boolean => {
  const phoneRegex = /^\d{8}$/
  return phoneRegex.test(phoneNumber)
}

const PageTwo = () => {
  const { formData, setFormData } = useContext(createUserContext)
  const [privacyModal, setPrivacyModal] = useState(false)
  const [termsModal, setTermsModal] = useState(false)
  const emailValid = formData.email_address
    ? validateEmail(formData.email_address)
      ? ''
      : 'Ugylding format, riktig format: example@email.com'
    : ''

  const phoneNumberValid = formData.phone_number
    ? phoneNumberValidation(formData.phone_number)
      ? ''
      : 'Ugyldig telefonnummer, må være 8 siffer langt'
    : ''
  return (
    <>
      <Grid container justifyContent='center'>
        <TextInputField
          startAdornment={<HouseIcon />}
          propKey='address'
          value={formData.address}
          label='Gateadresse'
          setField={setFormData}
        />
        <TextInputField
          startAdornment={<MarkunreadMailboxIcon />}
          propKey='postal_code'
          value={formData.postal_code}
          label='Postnummer'
          setField={setFormData}
        />
        <TextInputField
          error={phoneNumberValid}
          startAdornment={<div>+47</div>}
          propKey='phone_number'
          value={formData.phone_number}
          label='Telefonnummer'
          setField={setFormData}
        />
        <TextInputField
          error={emailValid}
          startAdornment={<AlternateEmailIcon />}
          propKey='email_address'
          value={formData.email_address}
          label='Epost'
          setField={setFormData}
        />
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item>
            <Checkbox
              checked={formData.terms}
              onChange={(_, checked) =>
                setFormData(prevState => ({ ...prevState, terms: checked }))
              }
            />
          </Grid>
          <Grid item>
            Jeg godtar{' '}
            <span
              onClick={() => setTermsModal(true)}
              style={{
                textDecoration: 'underline',
                color: colors.main,
                cursor: 'pointer',
              }}>
              vilkår
            </span>{' '}
            og{' '}
            <span
              onClick={() => setPrivacyModal(true)}
              style={{
                textDecoration: 'underline',
                color: colors.main,
                cursor: 'pointer',
              }}>
              personvern
            </span>
          </Grid>
        </Grid>
      </Grid>
      <TermsModal
        setIsOpen={setPrivacyModal}
        isOpen={privacyModal}
        privacy={true}
      />
      <TermsModal
        setIsOpen={setTermsModal}
        isOpen={termsModal}
        privacy={false}
      />
    </>
  )
}

export default PageTwo
