import MainCard from '../Views/MainPage/MainCard'
import { Grid } from '@mui/material'
import { colors } from '../themes/generalStyles'
import { useContext } from 'react'
import screenScaleContext from '../Contexts/ScreenScaleContext'

const AboutUsCard = ({
  image,
  name,
  text,
}: {
  image: string
  name: string
  text: string
}): JSX.Element => {
  const { smallScreenWidth } = useContext(screenScaleContext)
  return (
    <Grid container spacing={1}>
      <Grid item sx={{ width: smallScreenWidth ? '100%' : '180px' }}>
        <div style={{ paddingTop: -10, height: '100%' }}>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            sx={{ marginTop: 0, height: '100%' }}>
            <img
              alt='benji'
              src={image}
              style={{
                width: '70%',
                height: 'auto',
                borderRadius: 5,
              }}
            />
            <div
              style={{
                fontSize: 12,
                textAlign: 'center',
              }}>
              <div>{name}</div>
              <div style={{ color: colors.secondGray }}>Fysioterapeut</div>
            </div>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={true}>
        <div
          style={{
            height: '100%',
            paddingLeft: smallScreenWidth ? undefined : 10,
          }}>
          <Grid container justifyContent='center' alignItems='center'>
            <div
              style={{
                padding: 10,
                fontSize: 22,
              }}>
              {text}
            </div>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default AboutUsCard
